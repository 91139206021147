
const initColourPicker = function() {

    let colourOptionButtons = document.getElementsByClassName("colour-option");

    for (let i = 0; i < colourOptionButtons.length; i++) {
        colourOptionButtons[i].addEventListener('click', selectColourOption);
    }
    

}

function selectColourOption(event) {
    let optionSelected = event.srcElement.dataset.colour;
    document.body.dataset.colour = optionSelected;
    updateSelectedOption(optionSelected);
}

function updateSelectedOption(colour) {

    let colourOptionButtons = document.getElementsByClassName("colour-option");

    for (let i = 0; i < colourOptionButtons.length; i++) {

        if (colourOptionButtons[i].dataset.colour == colour) {
            colourOptionButtons[i].dataset.selected = true;
        }
        else {
            colourOptionButtons[i].dataset.selected = false;
        }
 
    }
}

export default initColourPicker;