import initColourPicker from "./scripts/colourPicker";

let navMenuOpen = false;

initColourPicker();
initMobileNav();
initLogoJiggles();

const studentSwiper = new Swiper('.student-slides', {
    // Optional parameters
    direction: 'horizontal',
    loop: true,
    pagination: {
      el: '.student-pagination',
    },
    a11y: {
        prevSlideMessage: 'Previous slide',
        nextSlideMessage: 'Next slide',
    },
    autoplay: {
        delay: 2000,
    }
});

const teacherSwiper = new Swiper('.teacher-slides', {
    // Optional parameters
    direction: 'horizontal',
    loop: true,
    pagination: {
      el: '.teacher-pagination',
    },
    a11y: {
        prevSlideMessage: 'Previous slide',
        nextSlideMessage: 'Next slide',
    },
    autoplay: {
        delay: 2000,
    }
});

function initMobileNav() {

    let navElement = document.getElementById("navigation");
    let mobileMenu = document.getElementById("mobile-menu");

    navElement.addEventListener('click', () => {

        mobileMenu.classList.add("animate-spin");

        if (navMenuOpen) {
            navElement.classList.remove("open");
            navMenuOpen = false;
        }
        else {
            navElement.classList.add("open");
            navMenuOpen = true;
        }

    });

    mobileMenu.addEventListener('animationend', () => {
        mobileMenu.classList.remove("animate-spin");
    });

}

function initLogoJiggles() {

    let headerLogoElement = document.getElementById("logo-header");
    let footerLogoElement = document.getElementById("logo-footer");

    console.log(headerLogoElement);

    headerLogoElement.addEventListener('pointerenter', () => {
        headerLogoElement.classList.add("animate");
    });

    headerLogoElement.addEventListener('animationend', () => {
        headerLogoElement.classList.remove('animate');
    });

    footerLogoElement.addEventListener('pointerenter', () => {
        footerLogoElement.classList.add("animate");
    });

    footerLogoElement.addEventListener('animationend', () => {
        footerLogoElement.classList.remove("animate");
    });

}